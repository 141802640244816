import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Alert,
  Avatar,
  Card,
  Container,
  Divider,
  Fab,
  FormControl,
  Grid,
  Icon,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import RadioGroupControl from "../../components/controls/RadioGroupControl";
import SelectControl from "../../components/controls/SelectControl";
import PageHeader from "../../components/PageHeader";
import ServiceCard from "../../components/ServiceCard";
import useAppContext from "../../hooks/useAppContext";
import PersonalInfoForm from "./PersonalInfoForm";

const objRecord = {
  industry: "Advertising",
  email: "",
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  phoneNumber: "",
  company: "",
  taxNumber: "",
  whereFindUs: "",
};

export default function AdvertisingForm() {
  const { apiRequest, response, setResponse } = useAppContext();

  const [values, setValues] = useState({ ...objRecord });
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [imgList, setImgList] = useState([]);
  const [isEmailConflict, setEmailConflict] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const location = useLocation();

  const validate = (input = values) => {
    let temp = { ...errors };

    // Customer Info validation
    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("firstName" in input)
      temp.firstName = input.firstName ? "" : "This field is required.";
    if ("lastName" in input)
      temp.lastName = input.lastName ? "" : "This field is required.";
    if ("city" in input)
      temp.city = input.city ? "" : "This field is required.";
    if ("state" in input)
      temp.state = input.state ? "" : "This field is required.";
    if ("country" in input)
      temp.country = input.country ? "" : "This field is required.";
    if ("zipCode" in input)
      temp.zipCode = input.zipCode ? "" : "This field is required.";
    if ("phoneNumber" in input)
      temp.phoneNumber = input.phoneNumber ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e, switchName = null) => {
    const { name, value } = e.target;
    values[name] = value;
    if (switchName) values[switchName] = true;

    setValues({ ...values });
    validate({ [name]: value });
  };

  const [step, setStep] = useState(0);
  const [items, setItems] = useState([]);
  const nextStep = () => {
    // ARRAY: create selected services array;
    const x = Object.keys(values).filter((k) => k.startsWith("_") && values[k]);
    setItems(x);
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  const showPreview = async (e) => {
    if (e.target.files) {
      // Set selected images files to upload in values
      const name = e.target.name;
      const files = e.target.files;
      setValues((prev) => ({ ...values, [name]: files }));

      // Crate Image list from selected image files to upload
      const fileArray = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );

      setImgList({ ...imgList, [name]: fileArray });
      // setImgList((prev) => prev.concat(...imgList[name], fileArray));
      // Array.from(imgList[name]).map((file) => URL.revokeObjectURL(file));
    }
  };

  const renderImageList = (source) => {
    return (
      <ImageList variant="masonry" cols={4} gap={8}>
        {source.map((el) => (
          <ImageListItem key={el}>
            <img src={el} alt={el} />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);

      const data = {};
      const quoteServices = {};
      const quoteItems = {};
      const quoteInfo = {};
      for (const [key, val] of Object.entries(values)) {
        if (key.startsWith("_") && val) {
          const k = key.slice(1);
          quoteServices[k] = val;
          continue;
        }

        if (/^[A-Z]/.test(key[0]) && val) {
          quoteItems[key] = typeof val === "boolean" ? "Yes" : val;
          continue;
        }

        if (/^[a-z]/.test(key[0])) {
          data[key] = val;
          continue;
        }
      }

      for (const [key] of Object.entries(data)) {
        if (key.endsWith("_") || key.startsWith("_")) {
          delete data[key];
        }
      }

      let record = data;
      record.quoteServices = JSON.stringify(quoteServices);
      record.quoteItems = JSON.stringify(quoteItems);
      record.quoteInfo = JSON.stringify(quoteInfo);

      // Create formData
      const formData = new FormData();
      // Add quote values to formData
      for (const [key, val] of Object.entries(record)) {
        formData.append(key, val);
      }

      // Add fleetMarketingFile values to formData
      if (values.fleetMarketingFile) {
        for (const val of Object.values(values.fleetMarketingFile)) {
          formData.append("fleetMarketingFile", val);
        }
      }

      // Add windowGraphicsFile values to formData
      if (values.windowGraphicsFile) {
        for (const val of Object.values(values.windowGraphicsFile)) {
          formData.append("windowGraphicsFile", val);
        }
      }

      // Add printServiceFile values to formData
      if (values.printServiceFile) {
        for (const val of Object.values(values.printServiceFile)) {
          formData.append("printServiceFile", val);
        }
      }

      // Add SignsFile values to formData
      if (values.SignsFile) {
        for (const val of Object.values(values.SignsFile)) {
          formData.append("SignsFile", val);
        }
      }

      // Add flagsAndBannersFile values to formData
      if (values.flagsAndBannersFile) {
        for (const val of Object.values(values.flagsAndBannersFile)) {
          formData.append("flagsAndBannersFile", val);
        }
      }

      // Add exhibitStandsFile values to formData
      if (values.exhibitStandsFile) {
        for (const val of Object.values(values.exhibitStandsFile)) {
          formData.append("exhibitStandsFile", val);
        }
      }

      // POST request using fetch
      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(`${apiRequest}/Quote`, requestOptions);
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          if (response.status === 409) {
            setEmailConflict(true);
            return;
          }

          if (response.status === 404 || response.status === 500) {
            setNotFound(true);
            return;
          }

          // get error message from body or default to response status
          const error = (json && json.message) || response.status;
          throw Error(error);
        }

        setEmailConflict(false);
        setNotFound(false);
        setResponse(record);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <>
      {response && (
        <Navigate to="/thank-you" state={{ from: location }} replace />
      )}
      <PageHeader
        title="Advertising Services"
        subTitle="Select one or more services to receive a tailored quote within 24h"
        icon="wi-advertising"
      />
      <Container sx={{ pb: 5 }}>
        <form onSubmit={handleSubmit}>
          <Slide
            direction="right"
            in={step === 0 ? true : false}
            style={{ display: step === 0 ? "flex" : "none" }}
          >
            <Grid container>
              <Grid item xs={12} md={9}>
                <Grid container spacing={1} align="left">
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "FleetMarketingOption",
                        "FleetMarketingFile",
                        "fleetMarketingNumberOfVehicles",
                        "FleetMarketingVehicleBrand",
                        "FleetMarketingVehicleYear",
                        "FleetMarketingVehicleColor",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_FleetMarketing"
                      icon="wi-fleet-marketing"
                      title="Fleet Marketing"
                      paragraph="We provide our customers to maximize marketing money to grow
                      their business through unique and effective vehicle wraps.
                      Whether your service vehicle fleet consists of small
                      passenger cars or large cargo vans, 2wrap.com can help you
                      leverage your corporate brand by identifying your vehicles
                      with distinctive designs."
                    >
                      <RadioGroupControl
                        aria-label="fleet marketing"
                        name="FleetMarketingOption"
                        onChange={(e) =>
                          handleInputChange(e, "_FleetMarketing")
                        }
                        options={[
                          {
                            label: "Design Request",
                            value: "Design Request",
                          },
                          {
                            label: "I Have a Design",
                            value: "I Have a Design",
                          },
                        ]}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="fleetMarketing-file">
                          <input
                            id="fleetMarketing-file"
                            name="fleetMarketingFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.fleetMarketingFile &&
                          renderImageList(imgList.fleetMarketingFile)}
                      </FormControl>
                      <InputControl
                        type="number"
                        label="Number Of Vehicles"
                        name="FleetMarketingNumberOfVehicles"
                        onChange={(e) =>
                          handleInputChange(e, "_FleetMarketing")
                        }
                      />
                      <InputControl
                        label="Vehicle Brand"
                        name="FleetMarketingVehicleBrand"
                        onChange={(e) =>
                          handleInputChange(e, "_FleetMarketing")
                        }
                      />
                      <InputControl
                        label="Vehicle Type"
                        name="FleetMarketingVehicleType"
                        onChange={(e) =>
                          handleInputChange(e, "_FleetMarketing")
                        }
                      />
                      <InputControl
                        label="Vehicle Year"
                        name="FleetMarketingVehicleYear"
                        onChange={(e) =>
                          handleInputChange(e, "_FleetMarketing")
                        }
                      />
                      <InputControl
                        label="Vehicle Color"
                        name="FleetMarketingVehicleColor"
                        onChange={(e) =>
                          handleInputChange(e, "_FleetMarketing")
                        }
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "WindowGraphicsOption",
                        "windowGraphicsFile",
                        "WindowGraphicsQuantity",
                        "WindowGraphicsHeight",
                        "WindowGraphicsWidth",
                        "WindowGraphicsUnits",
                        "WindowGraphicsMoreInfo",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_WindowGraphics"
                      icon="wi-window-graphics"
                      title="Window Graphics"
                      paragraph="Decorating your facility&rsquo;s windows is a smart, effective and
                      beautiful way to maximize your use of space. You can rely on
                      the professional design and printing team at 2wrap.com. We
                      will help you create and install eye-catching graphics that
                      will significantly improve your business."
                    >
                      <RadioGroupControl
                        aria-label="window graphics"
                        name="WindowGraphicsOption"
                        onChange={(e) =>
                          handleInputChange(e, "_WindowGraphics")
                        }
                        options={[
                          {
                            label: "Design Request",
                            value: "Design Request",
                          },
                          {
                            label: "I Have a Design",
                            value: "I Have a Design",
                          },
                        ]}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="windowGraphics-file">
                          <input
                            id="windowGraphics-file"
                            name="windowGraphicsFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.windowGraphicsFile &&
                          renderImageList(imgList.windowGraphicsFile)}
                      </FormControl>
                      <InputControl
                        type="number"
                        label="Quantity"
                        name="WindowGraphicsQuantity"
                        onChange={(e) =>
                          handleInputChange(e, "_WindowGraphics")
                        }
                      />
                      <Stack direction="row">
                        <InputControl
                          type="number"
                          label="Dimension Height"
                          name="WindowGraphicsHeight"
                          onChange={(e) =>
                            handleInputChange(e, "_WindowGraphics")
                          }
                        />
                        <InputControl
                          type="number"
                          label="Dimension Width"
                          name="WindowGraphicsWidth"
                          onChange={(e) => {
                            handleInputChange(e, "_WindowGraphics");
                            if (!values.WindowGraphicsWidth)
                              setValues({ ...values, WindowGraphicsUnits: "" });
                          }}
                        />
                        {values.WindowGraphicsWidth && (
                          <SelectControl
                            label="Units"
                            name="WindowGraphicsUnits"
                            value={
                              values.WindowGraphicsUnits
                                ? values.WindowGraphicsUnits
                                : ""
                            }
                            onChange={(e) =>
                              handleInputChange(e, "_WindowGraphics")
                            }
                            options={[
                              { label: "cm", value: "cm" },
                              { label: "Inch", value: "Inch" },
                            ]}
                          />
                        )}
                      </Stack>
                      <InputControl
                        label="Tell me more"
                        name="WindowGraphicsMoreInfo"
                        multiline
                        rows={3}
                        onChange={(e) =>
                          handleInputChange(e, "_WindowGraphics")
                        }
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "GraphicDesignOption",
                        "GraphicDesignMoreInfo",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_GraphicDesign"
                      icon="wi-graphic-design"
                      title="Graphic Design"
                      paragraph="Together with you, our highly skilled design team will lay
                      down the foundations of your business. We create strong and
                      unique visual identities, logo design, corporate and
                      advertising material. Both for digital and printed purposes.
                      2wrap.com provides excellent graphic designing, that will
                      hit your desired target group."
                    >
                      <RadioGroupControl
                        aria-label="graphic graphics"
                        name="GraphicDesignOption"
                        onChange={(e) => handleInputChange(e, "_GraphicDesign")}
                        options={[
                          {
                            label: "Logo",
                            value: "Logo",
                          },
                          {
                            label: "Brandingbook",
                            value: "Brandingbook",
                          },
                          {
                            label: "Flyer",
                            value: "Flyer",
                          },
                          {
                            label: "Fleetmarketing",
                            value: "Fleetmarketing",
                          },
                          {
                            label: "Business cards",
                            value: "Business cards",
                          },
                          {
                            label: "Signs",
                            value: "Signs",
                          },
                          {
                            label: "Others",
                            value: "Others",
                          },
                        ]}
                      />
                      <InputControl
                        label="Tell Me More"
                        name="GraphicDesignMoreInfo"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_GraphicDesign")}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "PrintServiceOption",
                        "printServiceFile",
                        "PrintServiceQuantity",
                        "PrintServiceHeight",
                        "PrintServiceWidth",
                        "PrintServiceUnits",
                        "PrintServiceMoreInfo",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_PrintService"
                      icon="wi-print-service"
                      title="Print Service"
                      paragraph="We offer Large format printing is to our partner
                      professionals. Business to business: for wrappers and sign
                      makers. A service from experts to experts. We bring you 25
                      years of experience in this specialized field. And our team
                      of highly skilled experts is able to understand all your
                      requests. Tell them about your job."
                    >
                      <RadioGroupControl
                        aria-label="print service"
                        name="PrintServiceOption"
                        onChange={(e) => handleInputChange(e, "_PrintService")}
                        options={[
                          {
                            label: "Design Request",
                            value: "Design Request",
                          },
                          {
                            label: "I Have a Design",
                            value: "I Have a Design",
                          },
                        ]}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="printService-file">
                          <input
                            id="printService-file"
                            name="printServiceFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.printServiceFile &&
                          renderImageList(imgList.printServiceFile)}
                      </FormControl>
                      <InputControl
                        type="number"
                        label="Quantity"
                        name="PrintServiceQuantity"
                        onChange={(e) => handleInputChange(e, "_PrintService")}
                      />
                      <Stack direction="row">
                        <InputControl
                          type="number"
                          label="Dimension Height"
                          name="PrintServiceHeight"
                          onChange={(e) =>
                            handleInputChange(e, "_PrintService")
                          }
                        />
                        <InputControl
                          type="number"
                          label="Dimension Width"
                          name="PrintServiceWidth"
                          onChange={(e) => {
                            handleInputChange(e, "_PrintService");
                            if (!values.PrintServiceWidth)
                              setValues({ ...values, PrintServiceUnits: "" });
                          }}
                        />
                        {values.PrintServiceWidth && (
                          <SelectControl
                            label="Units"
                            name="PrintServiceUnits"
                            value={
                              values.PrintServiceUnits
                                ? values.PrintServiceUnits
                                : ""
                            }
                            onChange={(e) =>
                              handleInputChange(e, "_PrintService")
                            }
                            options={[
                              { label: "cm", value: "cm" },
                              { label: "Inch", value: "Inch" },
                            ]}
                          />
                        )}
                      </Stack>
                      <InputControl
                        label="Tell Me More"
                        name="PrintServiceMoreInfo"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_PrintService")}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "SignsOption",
                        "signsFile",
                        "SignsQuantity",
                        "SignsHeight",
                        "SignsWidth",
                        "SignsUnits",
                        "SignsMoreInfo",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_Signs"
                      icon="wi-signs"
                      title="Signs"
                      paragraph="A sign shows a thought, a wish or a command. Advertise,
                      announce, or celebrate with beautiful custom signs. We
                      create signs for your promotions, special events, grand
                      openings, trade shows, festivals and much more."
                    >
                      <RadioGroupControl
                        aria-label="signs"
                        name="SignsOption"
                        onChange={(e) => handleInputChange(e, "_Signs")}
                        options={[
                          {
                            label: "Design Request",
                            value: "Design Request",
                          },
                          {
                            label: "I Have a Design",
                            value: "I Have a Design",
                          },
                        ]}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="Signs-file">
                          <input
                            id="Signs-file"
                            name="signsFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.signsFile && renderImageList(imgList.signsFile)}
                      </FormControl>
                      <InputControl
                        type="number"
                        label="Quantity"
                        name="SignsQuantity"
                        onChange={(e) => handleInputChange(e, "_Signs")}
                      />
                      <Stack direction="row">
                        <InputControl
                          type="number"
                          label="Dimension Height"
                          name="SignsHeight"
                          onChange={(e) => handleInputChange(e, "_Signs")}
                        />
                        <InputControl
                          type="number"
                          label="Dimension Width"
                          name="SignsWidth"
                          onChange={(e) => {
                            handleInputChange(e, "_Signs");
                            if (!values.SignsWidth)
                              setValues({ ...values, SignsUnits: "" });
                          }}
                        />
                        {values.SignsWidth && (
                          <SelectControl
                            label="Units"
                            name="SignsUnits"
                            value={values.SignsUnits ? values.SignsUnits : ""}
                            onChange={(e) => handleInputChange(e, "_Signs")}
                            options={[
                              { label: "cm", value: "cm" },
                              { label: "Inch", value: "Inch" },
                            ]}
                          />
                        )}
                      </Stack>
                      <InputControl
                        label="Tell Me More"
                        name="SignsMoreInfo"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_Signs")}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "FlagsAndBannersOption",
                        "flagsAndBannersFile",
                        "FlagsAndBannersQuantity",
                        "FlagsAndBannersHeight",
                        "FlagsAndBannersWidth",
                        "FlagsAndBannersUnits",
                        "FlagsAndBannersMoreInfo",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_FlagsAndBanners"
                      icon="wi-flags-banners"
                      title="Flags and Banners"
                      paragraph="Window tinting offers elegant privacy. A beautifully sleek
                      appearance while guarding your world against the outside."
                    >
                      <RadioGroupControl
                        aria-label="flags and banners"
                        name="FlagsAndBannersOption"
                        onChange={(e) =>
                          handleInputChange(e, "_FlagsAndBanners")
                        }
                        options={[
                          {
                            label: "Design Request",
                            value: "Design Request",
                          },
                          {
                            label: "I Have a Design",
                            value: "I Have a Design",
                          },
                        ]}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="flagsAndBanners-file">
                          <input
                            id="flagsAndBanners-file"
                            name="flagsAndBannersFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.flagsAndBannersFile &&
                          renderImageList(imgList.flagsAndBannersFile)}
                      </FormControl>
                      <InputControl
                        type="number"
                        label="Quantity"
                        name="FlagsAndBannersQuantity"
                        onChange={(e) =>
                          handleInputChange(e, "_FlagsAndBanners")
                        }
                      />
                      <Stack direction="row">
                        <InputControl
                          type="number"
                          label="Dimension Height"
                          name="FlagsAndBannersHeight"
                          onChange={(e) =>
                            handleInputChange(e, "_FlagsAndBanners")
                          }
                        />
                        <InputControl
                          type="number"
                          label="Dimension Width"
                          name="FlagsAndBannersWidth"
                          onChange={(e) => {
                            handleInputChange(e, "_FlagsAndBanners");
                            if (!values.FlagsAndBannersWidth)
                              setValues({
                                ...values,
                                FlagsAndBannersUnits: "",
                              });
                          }}
                        />
                        {values.FlagsAndBannersWidth && (
                          <SelectControl
                            label="Units"
                            name="FlagsAndBannersUnits"
                            value={
                              values.FlagsAndBannersUnits
                                ? values.FlagsAndBannersUnits
                                : ""
                            }
                            onChange={(e) =>
                              handleInputChange(e, "_FlagsAndBanners")
                            }
                            options={[
                              { label: "cm", value: "cm" },
                              { label: "Inch", value: "Inch" },
                            ]}
                          />
                        )}
                      </Stack>
                      <InputControl
                        label="Tell Me More"
                        name="FlagsAndBannersMoreInfo"
                        multiline
                        rows={3}
                        onChange={(e) =>
                          handleInputChange(e, "_FlagsAndBanners")
                        }
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "ExhibitStandsOption",
                        "exhibitStandsFile",
                        "ExhibitStandsQuantity",
                        "ExhibitStandsHeight",
                        "ExhibitStandsWidth",
                        "ExhibitStandsUnits",
                        "ExhibitStandsMoreInfo",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_ExhibitStands"
                      icon="wi-exhibit-stands"
                      title="Exhibit Stands"
                      paragraph="Cracks and chips in windshields can be expensive and
                      hazardous. To prevent this we offer installation of high
                      quality windshield protection film."
                    >
                      <RadioGroupControl
                        aria-label="exhibit stands"
                        name="ExhibitStandsOption"
                        onChange={(e) => handleInputChange(e, "_ExhibitStands")}
                        options={[
                          {
                            label: "Design Request",
                            value: "Design Request",
                          },
                          {
                            label: "I Have a Design",
                            value: "I Have a Design",
                          },
                        ]}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="exhibitStands-file">
                          <input
                            id="exhibitStands-file"
                            name="exhibitStandsFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.exhibitStands &&
                          renderImageList(imgList.exhibitStands)}
                      </FormControl>
                      <InputControl
                        type="number"
                        label="Quantity"
                        name="ExhibitStandsQuantity"
                        onChange={(e) => handleInputChange(e, "_ExhibitStands")}
                      />
                      <Stack direction="row">
                        <InputControl
                          type="number"
                          label="Dimension Height"
                          name="ExhibitStandsHeight"
                          onChange={(e) =>
                            handleInputChange(e, "_ExhibitStands")
                          }
                        />
                        <InputControl
                          type="number"
                          label="Dimension Width"
                          name="ExhibitStandsWidth"
                          onChange={(e) => {
                            handleInputChange(e, "_ExhibitStands");
                            if (!values.ExhibitStandsWidth)
                              setValues({ ...values, ExhibitStandsUnits: "" });
                          }}
                        />
                        {values.ExhibitStandsWidth && (
                          <SelectControl
                            label="Units"
                            name="ExhibitStandsUnits"
                            value={
                              values.ExhibitStandsUnits
                                ? values.ExhibitStandsUnits
                                : ""
                            }
                            onChange={(e) =>
                              handleInputChange(e, "_ExhibitStands")
                            }
                            options={[
                              { label: "cm", value: "cm" },
                              { label: "Inch", value: "Inch" },
                            ]}
                          />
                        )}
                      </Stack>
                      <InputControl
                        label="Tell Me More"
                        name="ExhibitStandsMoreInfo"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_ExhibitStands")}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={["OtherDetails"]}
                      values={values}
                      setValues={setValues}
                      switchName="_Other"
                      icon="wi-other"
                      title="Other"
                      paragraph="Select this service for all other inquiries."
                    >
                      <InputControl
                        label="Please Specify"
                        name="OtherDetails"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_Other")}
                      />
                    </ServiceCard>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} align="center">
                <Fab
                  component={Link}
                  to="/"
                  aria-label="go back"
                  sx={{ mb: 3 }}
                >
                  <ArrowBackIcon />
                </Fab>
                <Typography color="text.secondary">
                  Select services form left and click CONFIRM once done
                </Typography>
                <Divider variant="middle" sx={{ my: 2 }}>
                  <Avatar>
                    <Icon className="wi-advertising" />
                  </Avatar>
                </Divider>
                {Object.keys(values).some(
                  (k) => /^[A-Z]/.test(k[0]) && values[k]
                ) ? (
                  <ButtonControl onClick={nextStep} text="CONFIRM" />
                ) : (
                  <ButtonControl text="CONFIRM" disabled />
                )}
              </Grid>
            </Grid>
          </Slide>

          <Slide
            direction="left"
            in={step === 1 ? true : false}
            style={{ display: step === 1 ? "flex" : "none" }}
          >
            <Grid container>
              <Grid item xs={12} md={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Card variant="outlined" sx={{ height: "100%" }}></Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PersonalInfoForm
                      values={values}
                      handleInputChange={handleInputChange}
                      errors={errors}
                      isEmailConflict={isEmailConflict}
                      setEmailConflict={setEmailConflict}
                      isNotFound={isNotFound}
                      setNotFound={setNotFound}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} align="center">
                <Fab onClick={prevStep} aria-label="go back" sx={{ mb: 3 }}>
                  <ArrowBackIcon />
                </Fab>
                <Typography color="text.secondary">
                  Selected services
                </Typography>
                <List>
                  {items &&
                    items.map((item) => (
                      <ListItem key={item}>
                        <ListItemIcon>
                          <CheckIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          primary={item
                            .slice(1)
                            .replace(/([A-Z])/g, " $1")
                            .trim()}
                        />
                      </ListItem>
                    ))}
                </List>
                <Divider variant="middle" sx={{ my: 2 }}>
                  {fetchError ? (
                    fetchError
                  ) : (
                    <Avatar>
                      <Icon className="wi-advertising" />
                    </Avatar>
                  )}
                </Divider>
                {!isSaving ? (
                  <ButtonControl
                    type="submit"
                    text="Submit"
                    isSaving={isSaving}
                  />
                ) : (
                  <ButtonControl
                    text="...Processing"
                    isSaving={isSaving}
                    disabled
                  />
                )}
              </Grid>
            </Grid>
          </Slide>
        </form>
      </Container>
    </>
  );
}
