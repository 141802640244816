import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PaletteIcon from "@mui/icons-material/Palette";
import {
  Alert,
  Avatar,
  Card,
  Container,
  Divider,
  Fab,
  FormControl,
  Grid,
  Icon,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import ColorsList from "../../components/ColorsList";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import RadioGroupControl from "../../components/controls/RadioGroupControl";
import PageHeader from "../../components/PageHeader";
import PopupDialog from "../../components/PopupDialog";
import ServiceCard from "../../components/ServiceCard";
import useAppContext from "../../hooks/useAppContext";
import PersonalInfoForm from "./PersonalInfoForm";

const objRecord = {
  industry: "Specialties",
  email: "",
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  phoneNumber: "",
  company: "",
  taxNumber: "",
  whereFindUs: "",
};

export default function SpecialtiesForm() {
  const { apiRequest, response, setResponse, popupDialog, setPopupDialog } =
    useAppContext();

  const [values, setValues] = useState({ ...objRecord });
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [imgList, setImgList] = useState([]);
  const [isEmailConflict, setEmailConflict] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const [colorAttr, setColorAttr] = useState();
  const location = useLocation();

  const validate = (input = values) => {
    let temp = { ...errors };

    // Customer Info validation
    if ("firstName" in input)
      temp.firstName = input.firstName ? "" : "This field is required.";
    if ("lastName" in input)
      temp.lastName = input.lastName ? "" : "This field is required.";
    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("city" in input)
      temp.city = input.city ? "" : "This field is required.";
    if ("state" in input)
      temp.state = input.state ? "" : "This field is required.";
    if ("country" in input)
      temp.country = input.country ? "" : "This field is required.";
    if ("zipCode" in input)
      temp.zipCode = input.zipCode ? "" : "This field is required.";
    if ("phoneNumber" in input)
      temp.phoneNumber = input.phoneNumber ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e, switchName = null) => {
    const { name, value } = e.target;
    values[name] = value;
    if (switchName) values[switchName] = true;

    validate({ [name]: value });
    setValues({ ...values });
  };

  const [step, setStep] = useState(0);
  const [items, setItems] = useState([]);
  const nextStep = () => {
    // ARRAY: create selected services array;
    const x = Object.keys(values).filter((k) => k.startsWith("_") && values[k]);
    setItems(x);
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  const showPreview = async (e) => {
    if (e.target.files) {
      // Set selected images files to upload in values
      const name = e.target.name;
      const files = e.target.files;
      setValues((prev) => ({ ...values, [name]: files }));

      // Crate Image list from selected image files to upload
      const fileArray = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );

      setImgList({ ...imgList, [name]: fileArray });
      // setImgList((prev) => prev.concat(...imgList[name], fileArray));
      // Array.from(imgList[name]).map((file) => URL.revokeObjectURL(file));
    }
  };

  const renderImageList = (source) => {
    return (
      <ImageList variant="masonry" cols={4} gap={8}>
        {source.map((el) => (
          <ImageListItem key={el}>
            <img src={el} alt={el} />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);

      const data = {};
      const quoteServices = {};
      const quoteItems = {};
      const quoteInfo = {};
      for (const [key, val] of Object.entries(values)) {
        if (key.startsWith("_") && val) {
          const k = key.slice(1);
          quoteServices[k] = val;
          continue;
        }

        if (/^[A-Z]/.test(key[0]) && val) {
          quoteItems[key] = typeof val === "boolean" ? "Yes" : val;
          continue;
        }

        if (/^[a-z]/.test(key[0])) {
          data[key] = val;
          continue;
        }
      }

      for (const [key] of Object.entries(data)) {
        if (key.endsWith("_") || key.startsWith("_")) {
          delete data[key];
        }
      }

      let record = data;
      record.quoteServices = JSON.stringify(quoteServices);
      record.quoteItems = JSON.stringify(quoteItems);
      record.quoteInfo = JSON.stringify(quoteInfo);

      // Create formData
      const formData = new FormData();
      // Add quote values to formData
      for (const [key, val] of Object.entries(record)) {
        formData.append(key, val);
      }

      // Add projectFiles values to formData
      if (values.projectFile) {
        for (const val of Object.values(values.projectFile)) {
          formData.append("projectFile", val);
        }
      }

      // POST request using fetch
      const requestOptions = {
        method: "POST",
        // headers: { "Content-Type": "application/json" },
        body: formData,
      };

      try {
        const response = await fetch(`${apiRequest}/Quote`, requestOptions);
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          if (response.status === 409) {
            setEmailConflict(true);
            return;
          }

          if (response.status === 404 || response.status === 500) {
            setNotFound(true);
            return;
          }

          // get error message from body or default to response status
          const error = (json && json.message) || response.status;
          throw Error(error);
        }

        setEmailConflict(false);
        setNotFound(false);
        setResponse(record);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <>
      {response && (
        <Navigate to="/thank-you" state={{ from: location }} replace />
      )}
      <PageHeader
        title="Specialties Services"
        subTitle="Select one or more services to receive a tailored quote within 24h"
        icon="wi-specialties"
      />
      <Container sx={{ pb: 5 }}>
        <form onSubmit={handleSubmit}>
          <Slide
            direction="right"
            in={step === 0 ? true : false}
            style={{ display: step === 0 ? "flex" : "none" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "ProjectDescription",
                        "ProjectDesignRequest",
                        "designRequestFile",
                        "ProjectColor",
                        "ProjectColorImg",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_Project"
                      icon="wi-specialties"
                      title="Project"
                      paragraph="Yacht wrapping delivers astonishing beauty, with visual
                      effects impossible to match by paint. Simultaneously,
                      wrapping provides a thin protective layer, shielding your
                      assets paint from the elements, UV and other small damage."
                    >
                      <InputControl
                        label="Project Description"
                        name="ProjectDescription"
                        onChange={(e) => handleInputChange(e, "_Project")}
                        multiline
                        rows={3}
                      />
                      <FormControl margin="dense" fullWidth>
                        <RadioGroupControl
                          aria-label="design request"
                          name="ProjectDesignRequest"
                          onChange={(e) => handleInputChange(e, "_Project")}
                          options={[
                            {
                              label: "Design Request",
                              value: "Design Request",
                            },
                            {
                              label: "I Have a Design",
                              value: "I Have a Design",
                            },
                          ]}
                        />
                        <label htmlFor="project-file">
                          <input
                            id="project-file"
                            name="designRequestFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Your Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.designRequestFile &&
                          renderImageList(imgList.designRequestFile)}
                      </FormControl>
                      <ButtonControl
                        startIcon={<PaletteIcon />}
                        text="Select Color"
                        variant="outlined"
                        onClick={(e) => {
                          setPopupDialog(true);
                          setColorAttr(["ProjectColor", "ProjectColorImg"]);
                        }}
                        fullWidth
                      />
                      <InputControl
                        label="Color"
                        name="ProjectColor"
                        value={values.ProjectColor ? values.ProjectColor : ""}
                        onChange={(e) => handleInputChange(e, "_Project")}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={["OtherDetails"]}
                      values={values}
                      setValues={setValues}
                      switchName="_Other"
                      icon="wi-other"
                      title="Other"
                      paragraph="Select this service for all other inquiries."
                    >
                      <InputControl
                        label="Please Specify"
                        name="OtherDetails"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_Other")}
                      />
                    </ServiceCard>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} align="center">
                <Fab
                  component={Link}
                  to="/"
                  aria-label="go back"
                  sx={{ mb: 3 }}
                >
                  <ArrowBackIcon />
                </Fab>
                <Typography color="text.secondary">
                  Select services form left and click CONFIRM once done
                </Typography>
                <Divider variant="middle" sx={{ my: 2 }}>
                  <Avatar>
                    <Icon className="wi-specialties" />
                  </Avatar>
                </Divider>
                {Object.keys(values).some(
                  (k) => /^[A-Z]/.test(k[0]) && values[k]
                ) ? (
                  <ButtonControl onClick={nextStep} text="CONFIRM" />
                ) : (
                  <ButtonControl text="CONFIRM" disabled />
                )}
              </Grid>
            </Grid>
          </Slide>

          <Slide
            direction="left"
            in={step === 1 ? true : false}
            style={{ display: step === 1 ? "flex" : "none" }}
          >
            <Grid container>
              <Grid item xs={12} md={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Card variant="outlined" sx={{ height: "100%" }}></Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PersonalInfoForm
                      values={values}
                      handleInputChange={handleInputChange}
                      errors={errors}
                      isEmailConflict={isEmailConflict}
                      setEmailConflict={setEmailConflict}
                      isNotFound={isNotFound}
                      setNotFound={setNotFound}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} align="center">
                <Fab onClick={prevStep} aria-label="go back" sx={{ mb: 3 }}>
                  <ArrowBackIcon />
                </Fab>
                <Typography color="text.secondary">
                  Selected services
                </Typography>
                <List>
                  {items &&
                    items.map((item) => (
                      <ListItem key={item}>
                        <ListItemIcon>
                          <CheckIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          primary={item
                            .slice(1)
                            .replace(/([A-Z])/g, " $1")
                            .trim()}
                        />
                      </ListItem>
                    ))}
                </List>
                <Divider variant="middle" sx={{ my: 2 }}>
                  {fetchError ? (
                    fetchError
                  ) : (
                    <Avatar>
                      <Icon className="wi-specialties" />
                    </Avatar>
                  )}
                </Divider>
                {!isSaving ? (
                  <ButtonControl
                    type="submit"
                    text="Submit"
                    isSaving={isSaving}
                  />
                ) : (
                  <ButtonControl
                    text="...Processing"
                    isSaving={isSaving}
                    disabled
                  />
                )}
              </Grid>
            </Grid>
          </Slide>
        </form>
      </Container>
      {/* PopupDialog */}
      <PopupDialog
        title="Select Color"
        popupDialog={popupDialog}
        setPopupDialog={setPopupDialog}
      >
        <ColorsList
          colorAttr={colorAttr}
          values={values}
          setValues={setValues}
          setPopupDialog={setPopupDialog}
        />
      </PopupDialog>
    </>
  );
}
